* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
  touch-action: pan-x pan-y;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/*@font-face {*/
/*  font-family: 'Superfats';*/
/*  src: url('../assets/fonts/Superfats-6PRM.woff') format('woff');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Lilta One';*/
/*  src: url('../assets/fonts/LiltaOneRus.woff') format('woff');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Phonk Regular';*/
/*  src: url('../assets/fonts/phonk-regular-demo.woff') format('woff');*/
/*}*/

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  margin: 9px 0;
  background-color: #151515;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #6d6d6d;
}

